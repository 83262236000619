
import 
	React, 
	{ 
		useEffect, 
		useState 
	} 								from 'react';

import { 
	GoogleAuthProvider, 
	signInWithPopup,
	getAuth,
	signInWithEmailAndPassword,
	setPersistence, 
	browserLocalPersistence
}									from 'firebase/auth';

import { useAuthState } 			from "react-firebase-hooks/auth";
import { auth } 					from '../utils/firebase';
import {
	useLocation,
	useNavigate
} 									from 'react-router-dom';

// - tbv Material UI (gebruik ik nu niet meer)
// import Button from '@mui/material/Button';

// [ ] icons implementeren 
import { FcGoogle } 				from "react-icons/fc";
// import { AiFillFacebook } from "react-icons/ai";




export default function Login() {

	const navigate 							= useNavigate();
	const location 							= useLocation();
	const state 							= location.state || {};

	const [user, loading] 					= useAuthState(auth);
	const googleProvider 					= new GoogleAuthProvider();

	const [credentials, setCredentials] 	= useState({email: "", password: ""});



	const GoogleLogin = async () => {

		try {
	
			const result = await signInWithPopup(auth,googleProvider);

			// In case of pending order:
			if( state.retryOrderConfig ){

				navigate(
					'/product/'+state.retryOrderConfig.priceId, 
					{ 
						state: { 
							course: 			state.retryOrderConfig.course,
							retryOrderConfig:	state.retryOrderConfig
						} });

				return;
			}

			// Standard redirect
			navigate('/');
		}
		catch(error){
			console.log(error);
		}
	}


	
	const loginWithEmailAndPassword		= async ()=>{
		
		const auth = getAuth();

		try {

			await setPersistence(auth, browserLocalPersistence)


			const result = await signInWithEmailAndPassword(auth, credentials.email, credentials.password);

			// In case of pending order:
			if( state.retryOrderConfig ){

				navigate(
					'/product/'+state.retryOrderConfig.priceId, 
					{ 
						state: { 
							course: 			state.retryOrderConfig.course,
							retryOrderConfig:	state.retryOrderConfig
						} });

				return;
			}

			return result;
		}
		catch(error){

			console.log(error);
		}
	}



	useEffect(() => {
		if (user) {
			navigate('/');
		} else {
			console.log("login");
		}
	}, [user]);

	
	return (

		// 	<div className="flex flex-col gap-4">
		// 		<button 
		// 			className="text-white bg-gray-700 p-4 w-full font-medium rounded-lg flex align-middle gap-4"
		// 			onClick={GoogleLogin}
		// 		> 
		// 			<FcGoogle className="text-2xl"/>
		// 			Sign in with Google
		// 		</button>
		// 		{/* <button>Sign in with Facebook</button> */}
		// 	</div>
		// </div>

		<div>
			<div
				style={{
					marginTop:				'10vh'
				}}
			>
				{/* Login */}

				{
					state.retryOrderConfig

					? 
					
					<div>
						<h3>Login to complete the order</h3>
					</div>
					
					: null
				}
			</div>


			<div
			
				style={{ 
					
					marginTop:					'2rem',

					display: 					'flex',
					gap: 						'2rem',
					flexDirection:				'column',
					alignItems: 				'center',			// verticale as
				}}
			>

				 <div className="login-container">

					<input
						className="login-input"
						value={credentials.email}
						type="email"
						placeholder="Please enter your e-mail address"
						onChange={event => setCredentials({ ...credentials, email: event.target.value })}
					/>

					<input
						className="login-input"
						value={credentials.password}
						type="password"
						placeholder="Enter your password"
						onChange={event => setCredentials({ ...credentials, password: event.target.value })}
					/>

					<button
						className={`login-button ${!(/^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,})$/.test(credentials.email)) ? 'disabled' : ''}`}
						disabled={ !(/^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,})$/.test(credentials.email)) }
						onClick={loginWithEmailAndPassword}
					>
						Login
					</button>

					<p 
						
						onClick = { ()=> {navigate( "/passwordReset")}}

						style = {{
							
							color: 						'var(--tertiary-color)',
							textDecoration: 			'none',
							cursor: 					'pointer'
						}}
					>
						Forgot password
					</p>
				</div>
			</div>



			<div className="divider-container">
				<div className="line"></div>
				<p>or</p>
				<div className="line"></div>
			</div>

			<div
				style={{ 
					display: 					'flex',
					alignItems: 				'flex-start',		// verticale as
					justifyContent:				'space-around',		// horizontale as
					padding:					20,
					color:						'var(--secondary-color)'
				}}
			>

				<button 
					style={{ 
						display: 					'flex',
						justifyContent:				'space-between',

						height: 					'50px',
						lineHeight: 				'50px', 
    					boxSizing: 					'border-box',
						paddingBottom:				20,
						paddingLeft:				12,
						paddingRight:				16,
						color:						'var(--secondary-color)',
						backgroundColor:			'var(--primary-color)',

						border:						'2px solid var(--secondary-color)',
						borderRadius: 				'8px',

						fontSize: 					'1rem',
						cursor: 					'pointer'
					}}
					onClick={GoogleLogin}
				>

					<div
						style={{ 
							padding:				2,
							paddingRight:			8,
						}}
					>
						<FcGoogle />
					</div>
					<div>
						Login with Google
					</div>
				</button>
			</div>

			<br></br>

			<div>
					<p 
					
						onClick 					= { ()=> {navigate( "/signup", { state: state })}}

						style 						= {{
														color: 						'var(--tertiary-color)',
														textDecoration: 			'none',
														cursor: 					'pointer'
													}}
					>
						No account? Sign up here
					</p>
			</div>
		</div>
	);
}