import VideoPlayer 			from '../components/VideoPlayer';
import 
	React, 
	{ 
		useState, 
		useEffect 
	} 						from 'react';
import { useAuth } 			from '../context/AuthContext';

import {
	useNavigate, 
	useLocation
} 							from 'react-router-dom';

import { ClipLoader } 		from 'react-spinners';

import { 
	ref, 
	listAll,
	getDownloadURL 
} 								from 'firebase/storage';
import { storage } 				from '../utils/firebase'; // Importeer de storage-configuratie



export default function Lessons() {

	// user stuff and routing
	/***
		(sep 24)
			- nieuwe methode (AuthContext) maakt gebruik van persistence waardoor gebruiker na page refresh toch ingelogd blijft
	*/
	const { user, loading } 					= useAuth();

	const navigate 								= useNavigate();
	const location 								= useLocation();

	const [pageLoading, setPageLoading] 		= useState(true);

	let course									= location.state.course;

	let courseId								= course.id;
	let storageFolderPath						= course.storageFolderPath;

	const [lessons, setLessons] 				= useState([]);
	const [activeLesson, setActiveLesson]		= useState(0);

	const [downloadLinks, setDownloadLinks] 	= useState([]);



	const goToLesson = async (index) =>{

		/***
			Fetch bladmuziek
		*/
		var filePath 		= storageFolderPath+'/bladmuziek/';
		var lessonNumber 	= index + 1;
		filePath 			= filePath +  lessonNumber.toString().padStart(2, '0');


		const downloadConfigs 			= await fetchAllImages(filePath);

		setDownloadLinks( downloadConfigs );

		setActiveLesson( index );
	}



	// Functie om alle URL's in een map op te halen
	const fetchAllImages = async (folderPath) => {

		const folderRef 			= ref(storage, folderPath);
		const downloadConfigs		= [];
	
		try {
			const result = await listAll(folderRef);

			for (const itemRef of result.items) {
				
				const url = await getDownloadURL(itemRef);
				const fileName = itemRef.name;
				
				downloadConfigs.push({ name: fileName, url: url });
			}

			return downloadConfigs;

		} 
		catch (error) {
			console.error("Fout bij ophalen van mapinhoud:", error);
			return [];
		}
	};



	useEffect(
		() => {

			const fetchLessons = async () => {

				try{
				
					if( user ){

						const idToken 		= await user.getIdToken();

						const headers 		= new Headers();
						headers.append('Content-Type', 'application/json');
						headers.append('Authorization', `Bearer ${idToken}`);

						const response = await fetch(

							'https://getcourselessonsforuser-zso6kje7mq-uc.a.run.app', 
						
							{
								method: 	'POST',
								headers: 	headers,
								body: 		JSON.stringify({courseId: courseId})
							}
						);


						if (response.ok) {
							// Parse het antwoord en stel myCourses in met de ontvangen gegevens
							const data = await response.json();

							if(data.length > 0){
								
								setLessons(data[0].data.lessons);
							}
						} 
						else {
							console.error('Fout bij het ophalen van lessen:', response.statusText);
						}
					}
				}
				catch (error) {
					console.error('1: Fout bij het ophalen van lessen:', error);
				}

				setPageLoading(false);
			};


			// Roep de functie aan bij het laden van de pagina
			fetchLessons();
		}, 
		[]
	); 




	// only load page if user is logged in
	if( 
		user
	){

		return (

			<>
			{ 
				pageLoading 
				
				? 
				
				(
					<div className="loading-indicator">
				
						<br></br>

						<ClipLoader 
							color			= 'var(--tertiary-color)'
							loading			= {pageLoading} 
							size			= {50} 
						/>
					</div>
				) 
				
				: 
				
				(
					<div
						style={{ 
							padding:				20,
							color:					'var(--secondary-color)'
						}}
					>

						{/******************************************* 
						
							Course title 
						
						********************************************/}
						{ 
							lessons.length > 0 &&

							<>

								<h1> { lessons[activeLesson].title } </h1>
							
								{/******************************************* 
								
									Container (for player and playlist) 
								
								*******************************************/}
								<div
									className = "video-playlist-container"
								>

									{/******************************************* 
									
										Video player 
										
									*******************************************/}
									<div
										className 			= "video-container"
									>
										<VideoPlayer 
											url 					= {lessons[activeLesson].url}
											hasRelativeDimensions 	= {true}
										/>
									</div>
								


									{/*******************************************

										Playlist 
									
									*******************************************/}
									<div
										className="playlist-container"
									>

										<ul
											style = {{
												textAlign: 'left'
											}}
										>
											{
												lessons.map( ( lesson, index ) => 

													<div
														key				= {index}
													>

														{/* 

															[ ] we willen eigenlijk nog aangeven welke les actief is (jan 24)

														*/}
														<h3 
															style			= {{
																				color:					'var(--tertiary-color)',
																				cursor: 				'pointer'
																			}}

															onClick			= { ()=> {goToLesson(index)}}
														>
															{ index + 1 }  -  { lesson.title }
														</h3>

														{ activeLesson == index  &&
															
															downloadLinks.map((link, index) => (
																<li key={index} style={{ margin: '10px 0', listStyleType: 'none' }}>
																	<a 
																		href		= {link.url} 
																		style		= {{ 
																						color: 				'var(--secondary-color)', 
																						textDecoration: 	'none', 
																						// fontWeight: 		'bold' 
																						margin: 			'24px'
																					}}
																		download
																	>
																		{link.name}
																	</a>
																</li>
															)) 
														}
													</div>
												)
											}
										</ul>
									</div>
								</div>
							</>
						}
					</div>
				)
			}
			</>
		)
	}
	/***
		redirect if user is not logged in
	*/
  	else{
		navigate('/');
	}
	
}
