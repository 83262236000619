
import React 					from "react"
import { 
	Link, 
	useMatch, 
	useResolvedPath, 
	useLocation 
} 								from "react-router-dom"

import { 
	auth 
} 								from "../utils/firebase";
import { 
	useAuthState 
} 								from "react-firebase-hooks/auth";

import ProductList 				from "../components/ProductList";



export default function Home() {
	
	const [user, loading] = useAuthState(auth);



	return (

		<div
			className					= "home-container"
		>

		
			{/* Container voor image en welkom */}
			<div
				className					= "cover-container"
			>
				
						<img 
							className			= "image-container"

							src					= 'cover-2-medium.png'
							alt					= ""
						/>
					
					{

						user ? 
						
							<div
								className 			= "welcome-container"
							>
								<h1>Welcome <br></br> {user.displayName}</h1>
							</div>

							: 
							
							<div
								className 			= "promo-container"
							>

								<h3>
									GUITAR COURSES FOR ALL LEVELS
								</h3>
								
								<div
									style 					= {{ 
																paddingBottom:			'10%',
																color:					'var(--tertiary-color)',
																fontFamily:				"Roboto, sans-serif"
															}} 
								>
									<p> 
										Improve your playing together with your teacher Thom Helwig (Guitar Pilgrim)
									</p>
								</div>
								
								<br>
								</br>
							</div> 
					}
			</div>


			{/* 
				ProductList 
			*/}
			<div
				style 			= {{ 
									paddingTop:			'1rem',
								}}
			>

				<ProductList>
				</ProductList>

			</div>
		</div>
	)
}
