import React from "react";
import ProductList from "../components/ProductList";


export default function Store() {
  
  	return (

		<div
			style = {{ 
				display: 					'flex',
				flexDirection: 				'column',
			}}
		>

			<div
				style = {{ 
					paddingTop:					'4rem',
					paddingLeft:				'4rem',
					paddingRight:				'4rem',	
				}}
			>
				<ProductList>
					{/* [ ] children */}
				</ProductList>
			</div>


		</div>
		)
}
