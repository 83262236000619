import ReactPlayer from 'react-player';			// https://www.npmjs.com/package/react-player




export default function VideoPlayer( props ) {
  	return (

		<>
		{
			! props.hasRelativeDimensions

			?

			<div>
				<ReactPlayer 
					url				= {props.url}
					controls 		= {true}
					light			= {props.light}
				/>
			</div>

			:

			<div

				style			= {{
									position: 			'relative',
									paddingTop: 		'56.25%', 		// 16:9 aspect ratio
									width: 				'100%',
								}}
			>
				
				<ReactPlayer 
		
					url				= {props.url}
					controls 		= {true}
					light			= {props.light}

					style			={{
										position: 	'absolute',
										top: 		0,
										left: 		0,
									}}
					width			="100%"
					height			="100%"
				/>
			</div>
		}
		</>
	)
}