
/***

	NavBar - variant: contrast
	--------------------------
	
		
		- opaque
		- contrasting colors 
		- broad header

*/

import 
	React, 
	{ 
		useState 
	} 						from 'react';

import { 
	Link,
	useLocation 
} 							from "react-router-dom"
import { auth } 			from "../utils/firebase";
import { useAuthState } 	from "react-firebase-hooks/auth";

import CustomLink 			from "../components/CustomLink"; 



export default function Navbar() {

	const [user, loading] = useAuthState(auth);

	const location = useLocation();

	// Controleer of de huidige locatie de homepage is
	const isHomePage = location.pathname === '/';



	// Hamburger menu (voor mobile)
	const [menuOpen, setMenuOpen] = useState(false);

	const toggleMenu = () => {
		setMenuOpen(!menuOpen);
	};



  	return (
		<>
			<nav 
				className			= "nav"
			>


				{/* 
					Hamburger menu - mobile
				*/}
				<button className="menu-button" onClick={toggleMenu}>
					☰
				</button>

				{/* Off-canvas - mobile */}
				<div 
					className			= {`offcanvas ${menuOpen ? 'open' : ''}`}
				>
					<button 
						className			= "offcanvas-close-button" 
						onClick				= {toggleMenu}
					>
						✕
					</button>

					<ul className			= "offcanvas-list">
						<CustomLink to="/">					Home			</CustomLink>
						<CustomLink to="/courses">			Courses 		</CustomLink>
						{
							user 
							? 
							<CustomLink to="/MyCourses">	My Courses 		</CustomLink> 
							: null
						}
						{
							user 
							? 
							(
								<NavButton 
									to="/" 
									onClick={() => auth.signOut()}
								>
															Logout			
								</NavButton>
							) 
							: 
							(
								<CustomLink to="/login">	
															Login			
								</CustomLink>
						)}
						{
							!user 
							? 
							<CustomLink to="/Signup">		Sign up		</CustomLink> 
							: 
							null
						}
					</ul>
				</div>



				{/* Site title */}
				<Link 
					to					= "/"
					className 			= {`site-title ${isHomePage ? 'large' : 'small'}`}
				>
					Guitar Pilgrim
				</Link>



				{/* Default nav menu - larger screens */}
				<ul className="nav-menu">
					<CustomLink to="/">					Home			</CustomLink>
					<CustomLink to="/courses">			Courses 		</CustomLink>
					{
						user 
						? 
						<CustomLink to="/MyCourses">	My Courses 		</CustomLink> 
						: null
					}
					{
						user 
						? 
						(
							<NavButton 
								to="/" 
								onClick={() => auth.signOut()}
							>
														Logout			
							</NavButton>
						) 
						: 
						(
							<CustomLink to="/login">	
														Login			
							</CustomLink>
					)}
					{
						!user 
						? 
						<CustomLink to="/Signup">		Sign up		</CustomLink> 
						: 
						null
					}
				</ul>
			</nav>
		</>
  	)
}



/***  
	[ ] nu maar ff aparte component van gemaakt want deze werd actief (waarschijnlijk omdat ie ok naar Home verwees) (dec 23)
*/
function NavButton({ to, children, ...props }) {

  	// const resolvedPath = useResolvedPath(to)
	const isActive = false

	return (
		<li 
			className={isActive ? "active" : ""}
		>
			<Link to={to} {...props}>
				{children}
			</Link>
		</li>
	)
}



// function CustomLink({ to, children, ...props }) {
//   	const resolvedPath = useResolvedPath(to)
// 	const isActive = useMatch({ path: resolvedPath.pathname, end: true })
// 	return (
// 		<li 
// 			className={isActive ? "active" : ""}
// 		>
// 			<Link to={to} {...props}>
// 				{children}
// 			</Link>
// 		</li>
// 	)
// }