import 
	React, 
	{ 
		useEffect, 
		useState
	} 						from 'react';
import { useNavigate } 		from 'react-router-dom';
import { db } 				from '../utils/firebase';
import { 
	collection, 
	getDocs
} 							from 'firebase/firestore';
import Card 				from "./Card";


export default function ProductList() {
	
	const navigate 							= useNavigate();

	/***
		Fetch courses
	*/
	const [courses, setCourses] = useState([]);

	useEffect(() => {
		const fetchData = async () => {
			try {
				// Verwijs naar de collectie die je wilt ophalen
				const querySnapshot = await getDocs(collection(db, 'courses'));

				// Zet de documenten om naar een array van objecten
				const docsArray = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
				
				// dev dev
				docsArray.forEach( doc => {

					if(doc.lessonTitles){
						
						const lessons = doc.lessonTitles.split(";");
						doc.lessonTitleArray = lessons;
					}
				})

				// Update de state met de opgehaalde documenten
				setCourses(docsArray);
			} 
			catch (error) {
				console.error("Error fetching courses: ", error);
			}
			};

			fetchData();
		}, 
		[]
	);



	return (
		<>
			<div className		= "grid-container">

				{
					courses.map(
						(course, index) => {

							return (

								<div 
									key				= {course.name} 
									className		= "tile-wrapper"
								>
									<div className		= "tile">

										<Card
											title			= {course.name}
											body			= {course.storeDescription}
											imageSrc		= {course.storeImageUrl}


											onClick			= { () => navigate(`/product/${course.priceId}`, { state: { course } }) }
										>

											{/* Child componenten worden hier geplaatst */}
											<div 
												style			= {{ 
																	display: 					'flex', 
																	justifyContent:				'flex-start',
																	alignItems: 				'center', 
																	marginTop: 					'10px',
																	marginLeft:					'8px',
																}}
											>	
												
												<div
													style			= {{ 
																		display: 			'flex', 
																		flexDirection:		'row',
																		alignItems: 		'center', 
																		
																		height: 			'60px',
																		padding: 			'6px',
																	}}
												>
													<div
														style			= {{ marginRight: 		'10px' }}
													>
													{ course.prices[0] }
													</div>
													
													<div
														style			= {{ marginRight: 		'10px' }}
													>
														/
													</div>
													
													<div>
														{ course.prices[1] }
													</div>
												</div> 
											</div>
										</Card>
									</div>
								</div>
							)
						}
					)
				}
			</div>
		</>
	)
}