import 
	React, { 
		useState, 
		useEffect 
	} 								from 'react';

import {
	useNavigate
} 									from 'react-router-dom';

import { 
	getAuth
} 									from "firebase/auth";

import MyCourseList 				from "../components/MyCourseList";
import { ClipLoader } 				from 'react-spinners';



export default function MyCourses() {

	const navigate 							= useNavigate();

	const [myCourses, setMyCourses] 		= useState([]);
	const [pageLoading, setPageLoading] 	= useState(true);

	useEffect(
		() => {

			const fetchMyCourses = async () => {

				try{
				
					const auth 					= getAuth();
					const idToken 				= await auth.currentUser.getIdToken();


					// Stuur een HTTP-verzoek naar de Firebase Cloud Function
					const response = await fetch(

						'https://getcoursesforuser-zso6kje7mq-uc.a.run.app', 
					
						{
							method: 'GET',
							headers: {
								Authorization: `Bearer ${idToken}`,
							},
						}
					);


					if (response.ok) {
					
						// Parse het antwoord en stel myCourses in met de ontvangen gegevens
						const data = await response.json();

						if( data.length > 0 ){
						
							setMyCourses(data[0].courses);
						}
					} 
					else {
						console.error('Fout bij het ophalen van cursussen:', response.statusText);
					}
				}
				catch (error) {
					console.error('1: Fout bij het ophalen van cursussen:', error);
				}

				setPageLoading(false);
			};


			fetchMyCourses();
		}, 
		[]
	); 



  	return (

		<div
			style = {{ 
				display: 					'flex',
				flexDirection: 				'column',
			}}
		>
			{ pageLoading ? (
				<div className		= "loading-indicator">
				<br></br>
				<ClipLoader 
					color			= 'var(--tertiary-color)'
					loading			= {pageLoading} 
					size			= {50} 
				/>
				</div>
			) : (


				
				
				<div
					style = {{ 
						paddingTop:			'4rem',
						paddingLeft:		'4rem',
						paddingRight:		'4rem',
					}}
				>

					{
						myCourses && myCourses.length > 0 
						
						? 
					
						(

							<MyCourseList
								courses = { myCourses }
							>
							</MyCourseList>
						) 
						
						: 
						
						(
							<div>
								<p 
									onClick = { ()=> {navigate( "/courses")}}					// courses = store (!)

									style = {{
										color: 						'var(--tertiary-color)',
										textDecoration: 			'none',
										cursor: 					'pointer'
									}}
								>
									You don't have any courses yet. Go to the courses
								</p>
							</div>
						)
					}
				</div>
			)}
		</div>
	)
}
