



export default function Card( props ) {
	
	const imageSrc		= props.imageSrc;
	const title			= props.title;
	const body			= props.body;


	return (
		<>
		
			<div 
				// className	="card-container"

				style 			= {{ 

									// background: 			'#FFFFFF',
									background:				'var(--primary-color)',
									// backgroundColor: 		'#007BFF',
									// color: 					'#950101',
									color:					'var(--secondary-color)',

									cursor: 				'pointer',

									
									// tekst óp plaatje
									// position: 				'relative',
									// textAlign: 				'center',
									

									// tekst onder plaatje
									// textAlign: 				'center', 			
									textAlign: 				'left', 					
									padding: 				'10px',

									transition: 			'transform 0.3s ease',  // Smooth transition for scale effect
									transform: 				'scale(1)',              // Default scale

								
								}}
				onMouseEnter	= {(e) => e.currentTarget.style.transform = 'scale(1.05)'}  // On hover, scale up
   				onMouseLeave	= {(e) => e.currentTarget.style.transform = 'scale(1)'}    // On mouse leave, return to normal
				onClick			= { props.onClick }
			>
			
				<img 
					// className	= "card-image"
				
					src			= { imageSrc }
					alt			=""

					style		= {{
									display: 				'block',

									width: 					'100%', 		// Optioneel: past de breedte van de afbeelding aan
									margin: 				'0 auto', 		// Centraal uitlijnen van de afbeelding

									borderRadius: 			'20px',
								}}
				>
				</img>
				
				<div 
					// className="bottom-centered"

					style		= {{ 
									fontSize: 			'24px',
									marginLeft:			'8px',
									marginTop: 			'24px',

									// fontFamily: 		"Roboto, sans-serif"
								}}
				>
					{ title }
				</div>

				<div

					style		= {{ 
									fontSize: 			'14px',
									marginTop: 			'16px',
									marginLeft:			'8px',
									// color:				'rgb(120,120,120)',
									// color:				'var(--quaternary-color)',
									color:				'var(--tertiary-color)',

									fontFamily: 		"Roboto, sans-serif",
									fontWeight: 		'100'
								}}
				>
						{ body }
					
				</div>

				<div>
					{props.children}
				</div>
				
			</div>
		</>
	)
}
