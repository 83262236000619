
import Card from "./Card";

import { getFunctions } from "firebase/functions";
import { app } from '../utils/firebase';

import { getAuth } from "firebase/auth";
import { useNavigate } from 'react-router-dom';




export default function MyCourseList( props ) {
	
	const auth 			= getAuth();
	const functions 	= getFunctions(app, "us-central1");
	const navigate 		= useNavigate();


	const courses		= props.courses;


	const goToCourse = async (course) => {

		navigate(
			`/lessons`,
			{
				state: 	{
							course:		course
						}
			}
		);
	}



	return (
		<>
			<div className="grid-container">

				{
					courses.map(
						(course, index) => 
												
							<div 
								className		= "tile-wrapper"
								key				= {course.id}
							>
								<div className="tile">
									<Card
										title		= { course.name }
										imageSrc 	= { course.myCoursesImageUrl }

										onClick			= { ()=> { 
															goToCourse( course ) 
														} }
									>
									</Card>
								</div>
							</div>
					)		
				}
			</div>
		</>
	)
}
